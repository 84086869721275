/* You can add global styles to this file, and also import other style files */

@import "assets/plugins/bootstrap/css/bootstrap.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/i18n/arb-cutsom.scss";
@import './assets/leaflet/MarkerCluster.css';
@import './assets/leaflet/MarkerCluster.Default.css';

html,
body {
    /* height: 100%; */
    margin: 0;
    font-family: "Open Sans", sans-serif !important;
}

.mat-body,
.mat-body-2,
.mat-typography,
.mat-headline-5 {
    font: 400 14px/20px "Open Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.mat-h1,
.mat-typography h1 span {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
}

.mat-expansion-indicator::after {
    color: #ffffff !important;
}

.btn:focus,
.btn-active,
input:focus {
    outline: none !important;
    box-shadow: none;
}

.pointer {
    cursor: pointer !important;
}

.voilet {
    background: #5a2c81 !important;
    color: #ffffff !important;
}

.voilet-text {
    color: #5a2c81 !important;
}

.orange {
    color: #fff !important;
    background: #f8a05c !important;
    border: none !important;
    white-space: nowrap;
}

.blue {
    background: #16ceed !important;
    color: #ffffff !important;
}

.orange:active {
    border: none !important;
}

.orange-text {
    color: #f8a05c !important;
}

.grey {
    color: #fff !important;
    background: #726a79 !important;
    border: none !important;
}

.grey:active {
    border: none !important;
}

.white {
    color: #fff !important;
}

.lean-radius {
    border-radius: 2px !important;
}

.sans-serif {
    font-family: sans-serif !important;
}

.open-sans {
    font-family: "Open Sans" !important;
}

.login-dialog .mat-mdc-dialog-container {
    padding: 50px 50px 0px 50px;
    margin: 0;
    height: auto;
    overflow: inherit;
}

.city-dialog {
    max-width: 100vw !important;
}

.city-dialog .mat-mdc-dialog-content {
    padding: 0px !important;
    margin: 0;
}

.city-dialog .mat-mdc-dialog-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    margin: 0;
    height: auto;
    overflow: inherit;
    border-radius: 0px;
}

@media (max-width:768px) {
    .city-dialog .mat-mdc-dialog-container {
        padding: 0px 10px 0px 10px;
    }
}

.mat-mdc-dialog-content{
    max-height: none !important;
}

.mat-mdc-table-sticky{
    position: relative !important;
}

.login-dialog .mat-mdc-dialog-content {
    padding: 0px !important;
    margin: 0;
}

.input-background {
    background: #f8f8f8 !important;
}
p{
    margin: 0 !important;
}
.tb {
    border: 1px solid transparent !important;
}

.f-12 {
    font-size: 12px !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-30 {
    font-size: 30px !important;
}

.mat-mdc-menu-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.mat-mdc-tooltip {
    font-size: 14px !important;
    font-family: "Open Sans" !important;
}

.bleft {
    /* border-right: 1px solid #d7dbe6; */
    padding: 0 10px;
    height: inherit;
}

.cleft {
    padding: 0 10px;
    height: inherit;
}

.crumb-c {
    border-bottom: 1px solid #d7dbe6;
    height: 70px;
    padding: 10px 0px;
}
.form-group{
    width: 100%;
}
.form-input-l {
    height: 40px;
    min-width: 230px;
    padding-left: 10px;
    outline: none;
    border: 1px solid #dbdeea;
    text-align: left;
    border-radius: 5px !important;
}

.act-container {
    height: 100% !important;
    padding: 0px 10px !important;
}

@media screen and (max-width: 768px) {
    .mat-mdc-tooltip {
        font-size: 12px !important;
    }
}

.mat-mdc-slide-toggle.mat-checked .mat-ripple-element {
    background-color: #2bc36b !important;
}

.image-croper-dialog .mat-mdc-dialog-container {
    padding: 0;
    margin: 0;
    height: auto;
    overflow: inherit;
}

.image-croper-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 10px 20px;
    margin: 0;
}

.create-dialog .mat-mdc-dialog-container {
    padding: 0;
    margin: 0;
    height: auto;
    overflow: inherit;
}

.create-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 10px 30px;
    margin: 0;
}

.dialog-actions {
    margin-bottom: 0px !important;
    padding: 8px 0 !important;
    text-align: center;
}

.dialog-actions button {
    width: 120px !important;
    border-radius: 3px !important;
    border: 1px solid rgb(198, 198, 198);
}

.form-control:disabled{
    opacity: 0.7;
}
.form-control:focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
}

.mat-mdc-paginator-container {
    height: 45px !important;
    min-height: 0px !important;
    flex-wrap: nowrap !important;
}

.mat-mdc-paginator-page-size-label {
    margin: 0 4px;
    display: none !important;
}

.mat-mdc-paginator-range-label {
    border: 1px solid #dbdeea !important;
    border-radius: 30px !important;
    background-color: #e6e6e6 !important;
    padding: 2px 12px !important;
    margin: 3px 3px 0 3px !important;
    white-space: nowrap;
}

.mat-mdc-paginator-icon {
    border: 1px solid #dbdeea !important;
    border-radius: 50% !important;
    padding: 3px;
    color: #777;
}

.bg-white {
    background: #ffffff !important;
}

.grid-stack .grid-stack-item #jwplap0m1m8 {
    overflow: none !important;
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
    stroke: #f69230;
}


/* scroll bar css */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 0px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #e9d5fa;
    border-radius: 0px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #cf96ff;
}


/* mobile view */


/* width */

::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent !important;
    border-radius: 0px;
    background-color: rgba(234, 234, 234, 0.404);
}

@media screen and (max-width: 767px) {

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: transparent;
    }
}


/*  */

.mat-mdc-menu-panel {
    min-height: 0px !important;
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    /* background: rgba(255, 255, 255, 0.8); */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.error-message {
    padding: 6px 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    position: relative;
    top: 30vh;
    right: 10vw;
}

.info h4 {
    margin: 0 0 5px;
    color: #777;
}

.legend {
    text-align: left;
    line-height: 18px;
    color: #555;
    overflow: auto;
    /* display: flex; */
}

.info.legend.scrollVerticleAdded.leaflet-control {
    max-height: 320px;
    overflow-y: scroll;
    padding-top: 0;
}

.legend span {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    /* opacity: 0.7; */
}

.vr {
    border: 1px solid #bbbbbb;
    margin: 0px 3vh;
}

.chip-list {
    border-radius: 25px !important;
    cursor: pointer !important;
    font: normal normal 600 12px/25px "Open Sans" !important;
    /* display: flex !important;
  align-items: center !important;
  justify-content: space-between !important; */
    margin: 0px 0.4vw !important;
    padding-left: 5px;
}

.chip-default {
    background: #ffffff !important;
    border: 1px solid #d4d4d4 !important;
}

.chip-name {
    // padding: 0px 0;
}

.down-arrow {
    margin-right: 0.7vw;
}

.title {
    width: 100%;
    padding: 30px 40px 20px 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h2 {
        text-align: left;
        font-size: 17px;
        color: #000000;
        margin-bottom: 0px;
    }

    p {
        text-align: left;
        font-size: 15px;
        color: #000000;
    }

    @media (max-width: 768px) {
        padding: 30px 15px 20px 15px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1300px;
    }
}

.mat-loader {
    min-height: 3px;
    max-height: 3px;
    width: 100%;
}

// .mat-pseudo-checkbox-checked,
// .mat-pseudo-checkbox-indeterminate,
// .mat-accent .mat-pseudo-checkbox-checked,
// .mat-accent .mat-pseudo-checkbox-indeterminate {
//     background-color: #ff9800 !important;
// }

.cdk-drag-preview {
    box-sizing: border-box !important;
    border-radius: 4px !important;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.cdk-drag-placeholder {
    opacity: 0 !important;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
}

.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
}

.map-property-card {
    background: #fff;
    border-radius: 5px;
    font-family: "Open Sans", sans-serif !important;
}

.map-card-header {
    position: relative;
    width: 100%;
    max-height: 110px;
    text-align: center;
    border-bottom: 1px solid #eee;
    color: #555;
}

.img-card {
    width: 100%;
    max-height: 110px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.map-property_address {
    height: 38px;
}

.map-property_address p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    margin: 5px 0 !important;
    padding: 0 10px;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.disabled-add-btn {
    background: #b48dd5 !important;
}

.asterisk {
    color: red;
    font-size: 14px;
    font-weight: 600;
    margin: 0 3px;
}

.over_auto {
    overflow: auto !important;
}

.w-50 {
    width: 50px !important;
}

.disable-ng {
    pointer-events: none;
}

.disable-ng .multiselect-dropdown {
    background-color: #f5f5f2;
    color: lightgray
}

.w-200 {
    width: 100% !important;
}

//multiselect dropdown
$basecolor: #f8a05c;
$disable-background-color: #eceeef;

.multiselect-dropdown {
    position: relative;
    width: 100% !important;
    font-size: inherit;
    font-family: inherit;

    .dropdown-btn {
        display: inline-block;
        border: 1px solid #adadad;
        width: 100%;
        padding: 6px 12px;
        margin-bottom: 0;
        font-weight: normal;
        line-height: 1.52857143;
        text-align: left;
        vertical-align: middle;
        cursor: pointer;
        background-image: none;
        border-radius: 4px;

        .selected-item {
            border: 1px solid #f8a05c !important;
            margin-right: 4px;
            background: #f8a05c !important;
            padding: 0px 5px;
            color: #fff;
            border-radius: 2px;
            float: left;
            font-size: 12px !important;
            white-space: nowrap !important;
            min-width: 50px !important;
            max-width: 100% !important;
            margin-bottom: 2px;

            a {
                text-decoration: none;
            }
        }

        .selected-item span {
            display: inline-block;
            width: 40px !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            position: relative;
            top: 2px !important;
        }

        .selected-item a {
            position: relative;
            top: -3px !important;
        }

        .selected-item:hover {
            box-shadow: 1px 1px #959595;
        }

        .dropdown-down {
            display: inline-block;
            top: 10px;
            width: 0;
            height: 0;
            border-top: 10px solid #adadad;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }

        .dropdown-up {
            display: inline-block;
            width: 0;
            height: 0;
            border-bottom: 10px solid #adadad;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }
    }

    .disabled {
        &>span {
            background-color: $disable-background-color;
        }
    }
}

.dropdown-list {
    position: absolute;
    padding-top: 6px;
    width: 100% !important;
    z-index: 9999;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    margin-top: 0px !important;
    box-shadow: 0px 1px 5px #959595;

    ul {
        padding: 0px;
        list-style: none;
        overflow: auto;
        margin: 0px;
    }

    li {
        padding: 6px 10px;
        cursor: pointer;
        text-align: left;
    }

    .filter-textbox {
        border-bottom: 1px solid #ccc;
        position: relative;
        padding: 10px;

        input {
            border: 0px;
            width: 100%;
            padding: 0px 0px 0px 26px;
        }

        input:focus {
            outline: none;
        }
    }
}

.multiselect-item-checkbox input[type='checkbox'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus+div:before,
.multiselect-item-checkbox input[type='checkbox']:hover+div:before {
    border-color: #f8a05c !important;
    background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type='checkbox']:active+div:before {
    transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox']+div {
    position: relative;
    padding-left: 2em;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    margin: 0px;
    color: #000;
}

.multiselect-item-checkbox input[type='checkbox']+div:before {
    box-sizing: content-box;
    content: '';
    color: #f8a05c !important;
    position: absolute;
    top: 50%;
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -9px;
    border: 2px solid #f8a05c !important;
    text-align: center;
    transition: all 0.4s ease;
}

.multiselect-item-checkbox input[type='checkbox']+div:after {
    box-sizing: content-box;
    content: '';
    background-color: #f8a05c !important;
    position: absolute;
    top: 50%;
    left: 4px;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    transform: scale(0);
    transform-origin: 50%;
    transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:disabled+div:before {
    border-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox']:disabled:focus+div:before .multiselect-item-checkbox input[type='checkbox']:disabled:hover+div:before {
    background-color: inherit;
}

.multiselect-item-checkbox input[type='checkbox']:disabled:checked+div:before {
    background-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox']+div:after {
    background-color: transparent;
    top: 50%;
    left: 4px;
    width: 8px;
    height: 3px;
    margin-top: -4px;
    border-style: solid;
    border-color: #ffffff;
    border-width: 0 0 3px 3px;
    border-image: none;
    transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:after {
    content: '';
    transform: rotate(-45deg) scale(1);
    transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:before {
    animation: borderscale 200ms ease-in;
    background: #f8a05c !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked+div:after {
    transform: rotate(-45deg) scale(1);
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    position: relative;
    right: 0;
    top: 55% !important;
    color: rgb(0, 0, 0) !important;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0 !important;
    border-color: rgb(0, 0, 0) transparent !important;
    content: "";
    /* font-size: 8px !important; */
}

@keyframes borderscale {
    50% {
        box-shadow: 0 0 0 2px #f8a05c !important;
    }
}

textarea:focus,
input:focus,
button:focus {
    outline: none;
}


.Time_Control_Range {
    -webkit-appearance: none;
    background: transparent;
    top: 12px;
    position: relative;
    // min-width: 250px;
    // margin: 10px;
}

#Time_Slider_div {
    opacity: 0.5;
}

#Time_Slider_div:hover {
    opacity: 1;

}

.Time_Control_Range {
    -webkit-appearance: none;
    background: transparent;
    position: relative;
    left: 4%;
}


.Time_Control_Range::-webkit-slider-runnable-track {
    height: 3px;
    background: #ECD5FF 0% 0% no-repeat padding-box;
    width: 80%;
    border: none;
    border-radius: 3px;
}

.Time_Control_Range::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background: #795994 0% 0% no-repeat padding-box;
    margin-top: -4px;
    cursor: e-resize;
}
///

.long-inputbox {
    width: 100%!important;
    padding: 9px 10px !important;
    height: 40px;
    outline: none!important;
    background-color: white!important;
    border: 1px solid #dbdeea!important;
    margin: 3px 0px;
    border-radius: 3px!important;
}


.sort-inputbox {
    width: 120px!important;
    padding: 5px 10px!important;
    outline: none!important;
    background-color: white!important;
    border: 1px solid #dbdeea!important;
    margin: 0 10px;
    border-radius: 3px!important;
}

.sort-inputbox-arb {
    width: 150px!important;
    padding: 5px 10px!important;
    outline: none!important;
    background-color: white!important;
    border: 1px solid #dbdeea!important;
    margin: 0 10px;
    border-radius: 3px!important;
}

/////////////////////////// Mat css changes ////////////////////////////////////////////


.mdc-icon-button{
    font-size: 18px !important;
    width: 48px;
    height: 48px;
    padding: 0px !important;
}
.mdc-menu-surface.mat-mdc-select-panel{
    min-width: 230px;
    max-width: 100%;
    padding: 0 0 !important;
}
.mat-mdc-form-field-infix{
    min-height: 40px !important;
    font-size: 14px;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix{
    padding: 10px 0!important;
}
.mdc-text-field--filled {
    background-color: transparent !important;
}
.mdc-text-field--filled:hover {
    background-color: transparent !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel{
    max-width: 380px !important;
}
// .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate{
//     background-color: transparent !important;
// }
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
    background-color: #f8a05c  !important;
}
.mat-mdc-checkbox.mat-accent{
    --mdc-checkbox-selected-icon-color : #f8a05c !important;
    --mdc-checkbox-selected-focus-icon-color: #f8a05c;
    --mdc-checkbox-selected-hover-icon-color: #f8a05c;
    --mdc-checkbox-selected-icon-color: #f8a05c;
    --mdc-checkbox-selected-pressed-icon-color: #f8a05c;
}
.mdc-list-item__primary-text{
    display: flex !important;
}

.mall-study-floor{
    min-width: 230px !important;
}

.mat-mdc-select-arrow-wrapper {
 margin-left: 5px;
 padding-right: 10px;
}

.mat-mdc-option.mdc-list-item{
    font-size: 13px;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target{
    width: 20px !important;
    height: 30px !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: #ccc !important;
}

label#mat-mdc-slide-toggle-1-label{
    margin: 0;
}
.no-scrollbar .mdc-dialog__surface{
  overflow-y: hidden !important;
}
