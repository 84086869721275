// .middle-content-arb {
//     position: absolute !important;
//     top: 0px !important;
//     bottom: 0 !important;
//     left: 0px !important;
//     right: 47px !important;
//     padding: 0px 0px 0px 0px !important;
//     min-height: calc(100vh - 100px) !important;
//     touch-action: auto !important;
// }

.itemlist .mat-icon {
    direction: ltr;
}

.search-icon-arb-obj {
    position: absolute !important;
    left: 335px !important;
    right: auto !important;
}

.flex-none-arb {
    flex: none !important;
}

.search-icon-arb-rprt {
    position: absolute !important;
    left: auto !important;
    right: 10px !important;
}

.user-options-arb {
    text-decoration: none !important;
    padding: 0px 16px 0px 80px !important;
}

.lang-options-arb {
    text-decoration: none !important;
    padding: 0px 16px 0px 80px !important;
    border: 1px solid #e8e8e8 !important;
    width: 100% !important;
}
[dir="rtl"] .objective_tabs .mat-mdc-tab-body-wrapper{
    text-align: right;
    padding-right: 20px;
}

[dir="rtl"] .quries-list .list .viewed h5 {
    float: none !important;
    margin-left: 10px;
}

[dir="rtl"] .text_right_ar {
    text-align: right !important;
}

[dir="rtl"] .text_left_ar {
    text-align: left !important;
}

[dir="rtl"] .pos_right_ar {
    right: 0 !important;
    left: inherit !important;
}

[dir="rtl"] .pos_left_ar {
    left: 0 !important;
    right: inherit !important;
}

[dir="rtl"] .header-demo {
    margin-left: 0 !important;
}

[dir="rtl"] .header-nav-two .header-nav-links li:first-child a {
    padding-left: 30px !important;
    padding-right: 0 !important;
}

[dir="rtl"] .footer_links .list-unstyled,
[dir="rtl"] .social_icon,
[dir="rtl"] .follow_socialmedia {
    padding-right: 0;
}

[dir="rtl"] .slick-prev:before {
    content: "\f105" !important;
    font-family: FontAwesome;
}

[dir="rtl"] .slick-next:before {
    content: "\f104" !important;
    font-family: FontAwesome;
}

[dir="rtl"] .testimonials .img-name {
    flex-direction: row-reverse;
    text-align: right;
}

[dir="rtl"] .testimonial-statement img {
    transform: rotatey(180deg);
    right: 2px;
    left: inherit !important;
}

[dir="rtl"] .row_reverse_ar {
    flex-direction: row-reverse;
}

[dir="rtl"] .direction_ltr_ar {
    direction: ltr;
}

[dir="rtl"] .btn_subscribe .subscribe-btn {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

[dir="rtl"] .login-visibility a img {
    margin-left: 0.5rem !important;
    margin-right: inherit !important;
}

[dir="rtl"] .sidebarnormal .itemlist .menu-text {
    margin-left: inherit !important;
    margin-right: 20px;
}

[dir="rtl"] .profile-container .userName {
    margin-left: inherit !important;
    margin-right: 20px;
}

[dir="rtl"] .profile-container .save-profile {
    margin-right: inherit !important;
    margin-left: 1rem;
}

[dir="rtl"] .sectoral-items .btn_sectoral,
[dir="rtl"] .reports-list .btn_sectoral {
    right: inherit !important;
    left: 20px;
}

[dir="rtl"] .nav-item .sub-menu {
    right: 0;
    left: inherit !important;
}

[dir="rtl"] ul.nav .nav-item.mi_link {
    padding-right: 20px !important;
    padding-left: 0 !important;
}

[dir="rtl"] ul.nav .nav-item.item_city {
    padding-left: 20px !important;
    padding-right: 5px !important;
}


/* [dir="rtl"] .contact_content .contact_address {
    padding: 75px 35px 50px 35px !important;
} */

[dir="rtl"] .follow ul.follow_socialmedia li:first-child a {
    margin-right: 0 !important;
    margin-left: 15px !important;
}

[dir="rtl"] .navbar-header .navbar-toggler {
    padding: 10px 0px 10px 15px !important;
}

[dir="rtl"] .explore-product .ep-heading {
    padding: 15px 0px 0px 20px;
}

[dir="rtl"] .module_cards .box .content {
    padding: 0px 25px 0px 50px;
}


/* [dir="rtl"] .login-user .form-col {
    padding: 4% 6% 6% 12%;
} */

[dir="rtl"] .mob-login a {
    text-align: right !important;
}

[dir="rtl"] .btn_hamburger input {
    right: 10px;
    left: inherit !important;
}


/* [dir="rtl"] .request-demo .form-col {
    padding: 40px 85px 30px 0px !important;
}
[dir="rtl"] .request-demo .info-col {
    padding: 40px 40px 40px 0px !important;
} */


/* [dir="rtl"] .request-demo .info-col .info-heading {
    margin: 0px 45px 106px 0px;
} */

[dir="rtl"] .reports_breadcrumb {
    text-align: right !important;
}

@media (min-width: 600px) {
    [dir="rtl"] .reports_breadcrumb {
        width: 65px !important;
    }
}

@media (min-width: 1200px) {
    [dir="rtl"] .reports_breadcrumb {
        width: 152px !important;
    }
}

[dir="rtl"] .link_glossary {
    margin-right: 0px !important;
    margin-left: 20px;
}

[dir="rtl"] .unit-report .unit-filter .rep-filter {
    margin-left: 10px;
    margin-right: 0 !important;
}

[dir="rtl"] .unit-report .unit-filter .rep-filter .filter-applicable {
    margin-right: 3px;
}

[dir="rtl"] .margin_plus .side-arrow-right img {
    transform: rotate(180deg);
}

[dir="rtl"] .margin_minus .side-arrow-right img {
    transform: rotate(-180deg);
}

[dir="rtl"] .unit-report .unit-filter .filter-year {
    margin-right: 10px;
    margin-left: 0 !important;
}

[dir="rtl"] .unit-inventory .unit-report h2.b-label span {
    padding-right: 0px !important;
    padding-left: 10px;
}

[dir="rtl"] .generate .gen button {
    margin-right: 10px !important;
    margin-left: 0px !important;
}

[dir="rtl"] .report-form .filter-form .selected-value {
    text-align: right;
}

[dir="rtl"] .report-form .filter-form .btn_resetfilter {
    text-align: left !important;
}

[dir="rtl"] .about_sec ul.social_icon li:first-child {
    padding-left: 15px !important;
    padding-right: 0 !important;
}

[dir="rtl"] .navbar-nav {
    padding: 0;
}

[dir="rtl"] .tour_demo_info .tour_info h1 {
    padding: 15px 0px 0px 20px !important;
}

[dir="rtl"] .about_banner {
    background: url("../MIAssets/Web-page/about_ar.png") no-repeat center top !important;
    background-size: cover !important;
}

[dir="rtl"] .about_banner h1 {
    width: 100% !important;
    text-align: right;
}

[dir="rtl"] .banner_image_ar {
    display: block !important;
}

[dir="rtl"] .header-nav-one .lang_code {
    margin-right: 0 !important;
}

[dir="rtl"] .header-nav-one .lang_code a:first-child {
    border-left: 1px solid #bbb;
    border-right: 0 !important;
}

[dir="rtl"] .header-demo {
    padding: 0.25rem 1.5rem !important;
}

[dir="rtl"] .testimonial-content .testimonial-intro {
    max-width: 100% !important;
    text-align: center;
}


/* [dir="rtl"] .contact_header_banner {
    background: url("../MIAssets/Web-page/contact_ar.png") no-repeat center top !important;
    background-size: cover !important;
} */

[dir="rtl"] .request_demo {
    display: none;
}

[dir="rtl"] .request_demo_ar {
    display: block !important;
}

[dir="rtl"] section.banner_design {
    background: transparent linear-gradient(247deg, #fff1e8, #fff) !important;
}

[dir="rtl"] .banner-text .desktop_para {
    max-width: 300px;
}

[dir="rtl"] .user-options {
    padding: 0px 16px 0px 60px !important;
}

[dir="rtl"] .main_banner .web_banner {
    left: 0px;
    right: inherit !important;
}

[dir="rtl"] .top_banner span {
    max-width: 230px;
}

[dir="rtl"] .slick-prev {
    left: auto !important;
    right: 0px !important;
}

[dir="rtl"] .slick-next {
    left: 0 !important;
    right: auto !important;
}

[dir="rtl"] .content_slider #contentCarousel .carousel_control span {
    transform: rotate(180deg);
}

// [dir="rtl"] .mat-sidenav-content {
//     margin-left: 0 !important;
// }

[dir="rtl"] .content_slider #contentCarousel .carousel-control-prev {
    margin-left: 30px;
    margin-right: inherit !important;
}

[dir="rtl"] .content_slider #contentCarousel .carousel-control-next {
    margin-left: 0px !important;
}

[dir="rtl"] .content_slider #contentCarousel .carousel_control {
    margin-top: 0px !important;
}

[dir="rtl"] .banner_image {
    display: none;
}

[dir="rtl"] .banner_image_ar {
    display: block !important;
}

[dir="rtl"] .banner_design .banner-text {
    text-align: right;
    align-items: flex-end;
}

[dir="rtl"] .sectoral-items h2 img {
    margin-right: inherit !important;
    margin-left: 1rem;
}

[dir="rtl"] .sectoralbtn span {
    margin-left: inherit !important;
    margin-right: 11px !important;
}

[dir="rtl"] .profile-details .joinedin {
    text-align: left !important;
    margin-left: inherit !important;
    margin-right: 1.5rem;
}

[dir="rtl"] .date_of_birth .gender_male {
    border-radius: 0px 3px 3px 0px !important;
}

[dir="rtl"] .date_of_birth .gender_female {
    border-radius: 3px 0px 0px 3px !important;
}

[dir="rtl"] .margin_minus {
    margin-left: inherit !important;
    margin-right: -13%;
}

[dir="rtl"] .view-details .btn_group .learn_more {
    margin-right: inherit !important;
    margin-left: 15px;
}

[dir="rtl"] .sidebar_summary_arb .summary_continue .summary_footer {
    margin-left: 30px;
    margin-right: 0 !important;
}

[dir="rtl"] .view_detail_description p {
    text-align: right !important;
}

[dir="rtl"] .social-link {
    text-align: left !important;
}

[dir="rtl"] .act_btns {
    text-align: left;
}
[dir="rtl"] .update-layout{
    left: 0;
    right: unset !important;
    transform: rotate(180deg);
}

@media (max-width: 767px) {
    [dir="rtl"] .contact_content .form_inner {
        padding: 70px 15px 30px 15px !important;
    }
    [dir="rtl"] .contact_content .newsletter {
        padding: 30px 15px 30px 15px !important;
    }
    [dir="rtl"] .contact_content .contact_address {
        padding: 75px 15px 50px 15px !important;
    }
    [dir="rtl"] .header-nav-one .lang_code {
        margin-left: 0 !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    [dir="rtl"] .top_banner .main_banner .fd-text {
        text-align: right !important;
    }
    [dir="rtl"] .contact_header_banner.intro {
        background: url("../MIAssets/Web-page/contact_banner_ar.png") no-repeat center top !important;
        background-size: 100% !important;
    }
}

@media (max-width: 768px) {
    [dir="rtl"] .header-nav-one .lang_code a:last-child {
        padding-left: 0 !important;
        padding-right: 10px !important;
    }
}

@media (max-width: 599px) {
    [dir="rtl"] .intro .banner_image {
        left: 0px;
        right: inherit !important;
    }
    [dir="rtl"] .banner-text span {
        max-width: 200px;
    }
    [dir="rtl"] .banner_design .banner-text {
        width: 100%;
    }
    [dir="rtl"] .contact_sec .banner-text span {
        max-width: 210px !important;
    }
}

@media (min-width: 600px) {
    [dir="rtl"] .banner_design .banner-text {
        max-width: 200px;
    }
    [dir="rtl"] .intro .banner_image {
        left: 0px;
        right: inherit !important;
    }
    [dir="rtl"] .banner_design .intro {
        flex-direction: row;
    }
    [dir="rtl"] .contact_sec .intro {
        flex-direction: row-reverse !important;
    }
    [dir="rtl"] .info-col .info-heading {
        padding: 60px 15px 80px 40px !important;
    }
    [dir="rtl"] .form-col .form_column {
        padding: 60px 40px 80px 15px !important;
    }
    [dir="rtl"] .login_container .info-col .info-heading,
    [dir="rtl"] .requestdemo_container .info-col .info-heading {
        float: left !important;
    }
    [dir="rtl"] .login_banner_image {
        text-align: left !important;
    }
    [dir="rtl"] .content_slider #contentCarousel .carousel-item .heading h3 {
        max-width: 300px !important;
    }
    [dir="rtl"] .report_top_breadcrumb {
        font-size: 14px !important;
    }
}

@media (min-width: 768px) {
    [dir="rtl"] .explore-product .ep-sub-heading {
        padding: 0 25px 0 15px !important;
    }
}

@media (min-width: 992px) {
    [dir="rtl"] .fd-text {
        text-align: right !important;
    }
    [dir="rtl"] .explore-product .ep-sub-heading {
        padding: 0px 0px 35px 23% !important;
    }
}

@media (min-width: 1200px) {
    [dir="rtl"] .report_top_breadcrumb {
        font-size: 16px !important;
    }
    [dir="rtl"] .top_banner span {
        max-width: 342px;
    }
    [dir="rtl"] .contact_content .form_inner {
        float: left !important;
        padding: 70px 15px 30px 75px !important;
    }
    [dir="rtl"] .contact_content .contact_address {
        padding: 70px 75px 100px 15px !important;
    }
    [dir="rtl"] .contact_content .newsletter .newletter_container {
        float: left !important;
        padding: 0px 15px 0px 75px !important;
    }
}

[dir="rtl"] .mat-cal-icon{
    right: unset;
    left: 5px;
}

[dir="rtl"] .form-inline label {
    margin-left: 10px; 
  }
  
  [dir="rtl"] .form-inline mat-select {
    margin-left: 10px; 
  }
  